html {
	position: relative;
	min-height: 100%;
	padding: 0;
	margin: 0;
}
body {
	margin: 0;
	padding: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";*/
	font-family: 'MuseoSans', sans-serif;
	background-color: #f6f6f6;
  color: #333;
}
* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
*:before, *:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

/*
*
* ANIMATIONS
*
*/
@-webkit-keyframes loading {
  to { -webkit-transform: rotate(360deg); }
}
@-moz-keyframes loading {
  to { -moz-transform: rotate(360deg); }
}
@-ms-keyframes loading {
  to { -ms-transform: rotate(360deg); }
}
@keyframes loading {
  to { transform: rotate(360deg); }
}

.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit, .fade-exit.fade-exit-active {
  display: none;
}


/*
*
*
* TYPOGRAPHY
*
*/

@font-face {
  font-family:      'MuseoSans';
  font-style:       normal;
  font-weight:      100;
  src:              url('./fonts/MuseoSans/museosans_100-webfont.woff2') format('woff2'),
					url('./fonts/MuseoSans/museosans_100-webfont.woff') format('woff');
}
@font-face {
  font-family:      'MuseoSans';
  font-style:       italic;
  font-weight:      100;
  src:              url('./fonts/MuseoSans/museosans_100italic-webfont.woff2') format('woff2'),
					url('./fonts/MuseoSans/museosans_100italic-webfont.woff') format('woff');
}
@font-face {
  font-family:      'MuseoSans';
  font-style:       normal;
  font-weight:      300;
  src:              url('./fonts/MuseoSans/museosans_300-webfont.woff2') format('woff2'),
					url('./fonts/MuseoSans/museosans_300-webfont.woff') format('woff');
}
@font-face {
  font-family:      'MuseoSans';
  font-style:       italic;
  font-weight:      300;
  src:              url('./fonts/MuseoSans/museosans_300italic-webfont.woff2') format('woff2'),
					url('./fonts/MuseoSans/museosans_300italic-webfont.woff') format('woff');
}
@font-face {
  font-family:      'MuseoSans';
  font-style:       normal;
  font-weight:      500;
  src:              url('./fonts/MuseoSans/museosans_500-webfont.woff2') format('woff2'),
					url('./fonts/MuseoSans/museosans_500-webfont.woff') format('woff');
}
@font-face {
  font-family:      'MuseoSans';
  font-style:       italic;
  font-weight:      500;
  src:              url('./fonts/MuseoSans/museosans_500italic-webfont.woff2') format('woff2'),
					url('./fonts/MuseoSans/museosans_500italic-webfont.woff') format('woff');
}
@font-face {
  font-family:      'MuseoSans';
  font-style:       normal;
  font-weight:      700;
  src:              url('./fonts/MuseoSans/museosans_700-webfont.woff2') format('woff2'),
					url('./fonts/MuseoSans/museosans_700-webfont.woff') format('woff');
}
@font-face {
  font-family:      'MuseoSans';
  font-style:       italic;
  font-weight:      700;
  src:              url('./fonts/MuseoSans/museosans_700italic-webfont.woff2') format('woff2'),
					url('./fonts/MuseoSans/museosans_700italic-webfont.woff') format('woff');
}
@font-face {
  font-family:      'MuseoSans';
  font-style:       normal;
  font-weight:      900;
  src:              url('./fonts/MuseoSans/museosans_900-webfont.woff2') format('woff2'),
					url('./fonts/MuseoSans/museosans_900-webfont.woff') format('woff');
}
@font-face {
  font-family:      'MuseoSans';
  font-style:       italic;
  font-weight:      900;
  src:              url('./fonts/MuseoSans/museosans_900italic-webfont.woff2') format('woff2'),
					url('./fonts/MuseoSans/museosans_900italic-webfont.woff') format('woff');
}

/*
*
*
* REACT-TABLE-STYLES
*
*/

.ReactTable {
	border-radius: 5px;
	overflow: hidden;
}

.ReactTable .rt-thead.-header {
	background-color: rgb(90, 201, 236);
}

.ReactTable .rt-thead.-header .rt-th {
	color: white;
	padding: 10px;
	font-weight: 300;
}

.ReactTable .rt-thead.-filter .rt-th {
	color: white;
	padding: 10px 5px;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
	-webkit-box-shadow: inset 0 3px 0 0 white;
	box-shadow: inset 0 3px 0 0 white;
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
	-webkit-box-shadow: inset 0 -3px 0 0 white;
	box-shadow: inset 0 -3px 0 0 white;
}

.ReactTable .rt-thead .rt-th input[type="text"] {
	background-image: url(./images/searchIcon.png);
	background-position: calc(100% - 3px) center;
	background-repeat: no-repeat;
	background-size: auto calc(100% - 6px);
	padding: 5px;
	border: 1px solid #e5e5e5;
	border-radius: 5px;

	transition: border-color 0.2s ease-in, box-shadow 0.2s ease-in;
}

.ReactTable .rt-thead .rt-th input[type="text"]:hover {
	border-color: rgb(90, 201, 236);
	box-shadow: 0 0 0 1px rgb(90, 201, 236) inset;
}

.ReactTable .rt-tbody .rt-td {
	align-self: center;
	font-weight: 300;
	padding: 10px 5px;
	font-size: 16px;
}

.ReactTable .rt-tbody .rt-td.noPadding {
	padding: 0px;
}

/*
*
* React CSV
*
*/

.download-button {
  position: relative;
  display: block;
  width: 100%;
  font-size: 18px;
  padding: 5px 20px;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(229, 229, 229);
  cursor: pointer;
  border-radius: 5px;
  color: rgb(94, 94, 94);
  background-color: rgb(255, 255, 255);
  text-decoration: none;
  transition: color 0.2s ease-in, background-color 0.2s ease-in, border 0.2s ease-in;
}

.download-button:hover {
  color: white;
  background-color: #5ac9ec;
  border-color: #5ac9ec;
}
