/*MY STYLES*/
.react-datepicker-wrapper {
  display: block;
}
.react-datepicker__input-container {
  display: block;
}
.react-datepicker__input-container input {
  position: relative;
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  background-color: rgb(255, 255, 255);
  color: rgb(94, 94, 94);
  border: 1px solid rgb(229, 229, 229);
  border-radius: 5px;
  transition: border-color 0.2s ease-in, box-shadow 0.2s ease-in;
}
.react-datepicker__input-container input:hover {
  border: 1px solid #5ac9ec;
  box-shadow: 0 0 0 1px #5ac9ec inset;
}
.react-datepicker__day--keyboard-selected {
  background-color: #5ac9ec;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  padding: 0px;
}
.react-datepicker__time-container,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 95px;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 105px;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: #5ac9ec;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  line-height: 30px;
  padding: 0;
}
